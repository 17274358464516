import React, { useState, useEffect } from "react";
import { Col, Row, Table, Tag, Select, Divider, Button, Input } from "antd";

import {
  SearchOutlined,
  BulbOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

const SearchUsers = ({
  tokenObject,
  selectedUsers,
  setSelectedUsers,
  peopleData,
  setPeopleData,

  name,
  setName,
  title,
  setTitle,
  keywords,
  setKeywords,
  location,
  setLocation,
  seniority,
  setSeniority,
  tempData,
  setTempData,
  tableParams,
  setTableParams,
}) => {
  const [loading, setLoading] = useState(false);

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, data) => (
        <div>
          <img
            src={data?.photo_url}
            style={{ width: 60 }}
            onError={(e) =>
              (e.target.src = process.env.PUBLIC_URL + "/unknown.jpeg")
            }
          />
          <p>{data?.name}</p>
          <div style={{ display: "block" }}>
            {data?.linkedin_url ? (
              <a href={data?.linkedin_url} target="_blank">
                <LinkedinOutlined />
              </a>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (_, data) => (
        <div>
          <p>{data.title}</p>
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "organization",
      render: (_, data) => (
        <div>
          <img src={data?.organization?.logo_url} style={{ width: 50 }} />
          <a href={data?.organization?.website_url} target="_blank">
            <p>{data?.organization?.name}</p>
          </a>
          <div style={{ display: "block" }}>
            {data?.organization?.linkedin_url ? (
              <a href={data?.organization?.linkedin_url} target="_blank">
                <LinkedinOutlined />
              </a>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Seniority",
      dataIndex: "seniority",
      render: (_, data) => (
        <div>
          <p>{data.seniority}</p>
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "city",
      render: (_, data) => (
        <div>
          <p>
            {data.country} {data.city}
          </p>
        </div>
      ),
    },
    {
      title: "Keywords",
      dataIndex: "departments",
      render: (_, data) => {
        const unionArray = [
          new Set([
            ...data?.departments,
            ...data?.subdepartments,
            ...data?.functions,
          ]),
        ];

        const allUniqueArray = Array.from(unionArray[0]);
        console.log(unionArray);
        console.log(allUniqueArray);
        if (allUniqueArray && allUniqueArray.length > 0) {
          return (
            <>
              {allUniqueArray.map((tag) => {
                console.log(tag);
                return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
              })}
            </>
          );
        }
      },
    },
    {
      title: "Get AI Data",
      key: "operation",
      fixed: "right",
      render: (data) => {
        return (
          <Button
            type="primary"
            icon={<BulbOutlined />}
            onClick={() => {
              if (tempData?.length > 0) {
                // tempData.forEach((data) => {
                //   console.log(data);
                //   searchUserData(data.userName);
                // });
                setSelectedUsers(tempData);
              } else {
                setSelectedUsers([data]);
              }
            }}
          >
            Get AI Data!
          </Button>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setTempData(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  console.log(peopleData);

  const updateTableData = (page, pageSize) => {
    console.log(page, pageSize);
    fetch("https://sales-genius-api.onrender.com/api/searchUsers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: page,
        person_titles: title,
        name: name,
        q_keywords: keywords,
        person_locations: location,
        person_seniorities: seniority,
        per_page: 10,
      }),
    })
      .then((response) => {
        console.log(response);
        setLoading(false);
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setTableParams({
          current: response?.pagination?.page,
          pageSize: 10,
          total: response?.pagination?.total_entries,
          page: response?.pagination?.page,
          onChange: updateTableData,
        });
        setPeopleData(response?.people);
      })
      .catch((err) => {
        console.log("something bad happened");
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <Row style={{ background: "white" }}>
      <Col span={5}>
        <div style={{ padding: 16, borderRight: "solid" }}>
          <h5 style={{ marginTop: 8 }}>Search and Filter Prospects</h5>
          <Button
            type="primary"
            loading={loading}
            icon={<SearchOutlined />}
            style={{ width: "100%" }}
            onClick={() => {
              console.log(name);
              setLoading(true);

              fetch("https://sales-genius-api.onrender.com/api/searchUsers", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  page: 1,
                  person_titles: title,
                  name: name,
                  q_keywords: keywords,
                  person_locations: location,
                  person_seniorities: seniority,
                }),
              })
                .then((response) => {
                  console.log(response);
                  setLoading(false);
                  return response.json();
                })
                .then((response) => {
                  console.log(response);
                  setTableParams({
                    current: response?.pagination?.page,
                    pageSize: 10,
                    total: response?.pagination?.total_entries,
                    page: response?.pagination?.page,
                    onChange: updateTableData,
                  });
                  setPeopleData(response?.people);
                })
                .catch((err) => {
                  console.log("something bad happened");
                  setLoading(false);
                  console.log(err);
                });
            }}
          >
            Search for People
          </Button>
          <Divider></Divider>
          <h5>Name</h5>
          <Input
            placeholder="Prospect Name"
            style={{
              width: "100%",
            }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Divider></Divider>
          <h5>Title</h5>
          <Select
            mode="tags"
            placeholder="Prospect Title"
            style={{
              width: "100%",
            }}
            value={title}
            onChange={(value) => {
              setTitle(value);
            }}
            tokenSeparators={[","]}
            options={[
              { value: "engineer", label: "Engineer" },
              { value: "manager", label: "Manager" },
              { value: "owner", label: "Owner" },
              { value: "director", label: "Director" },
              { value: "ceo", label: "CEO" },
              { value: "marketing", label: "Marketing" },
            ]}
          />
          <Divider></Divider>
          <h5>Location</h5>
          <Select
            mode="tags"
            placeholder="Prospect Location"
            style={{
              width: "100%",
            }}
            value={location}
            onChange={(value) => {
              setLocation(value);
            }}
            tokenSeparators={[","]}
            options={[
              { value: "canada", label: "Canada" },
              { value: "toronto", label: "Toronto" },
              { value: "california", label: "California" },
            ]}
          />

          <Divider></Divider>
          <h5>Keywords</h5>
          <Input
            mode="tags"
            placeholder="Prospect Keywords"
            style={{
              width: "100%",
            }}
            value={keywords}
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
            tokenSeparators={[","]}
            options={[
              { value: "consultant", label: "Consultant" },
              { value: "sales", label: "Sales" },
              { value: "engineering", label: "Engineering" },
              { value: "product", label: "Product" },
            ]}
          />

          <Divider></Divider>

          <h5>Seniority</h5>
          <Select
            mode="tags"
            placeholder="Prospect Seniority"
            style={{
              width: "100%",
            }}
            value={seniority}
            onChange={(value) => {
              setSeniority(value);
            }}
            tokenSeparators={[","]}
            options={[
              { value: "entry", label: "Entry" },
              { value: "manager", label: "Manager" },
              { value: "senior", label: "Senior" },
              { value: "owner", label: "Owner" },
            ]}
          />
          <Divider></Divider>
        </div>
      </Col>
      <Col span={19}>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey="id"
          columns={columns}
          dataSource={peopleData}
          onChange={onChange}
          scroll={{
            x: 1300,
            y: 650,
          }}
          pagination={tableParams}
        />
      </Col>
    </Row>
  );
};

export default SearchUsers;
