import React, { useState, useEffect } from "react";
import LinkedInLogin from "./LinkedInLogin.js";
import {
  Card,
  Row,
  Col,
  Avatar,
  Button,
  Collapse,
  Input,
  Popover,
  Slider,
  Divider,
  Tabs,
  Skeleton,
} from "antd";

const { Meta } = Card;

const AiData = ({ userData, tokenObject }) => {
  const [laterAiData, setLaterAiData] = useState();

  const linkedinData = userData?.linkedinData;
  const aiData = userData?.aiData || laterAiData;
  const personality = aiData?.PersonalityTraits;

  useEffect(() => {
    if (!aiData) {
      const intervalFn = () => {
        console.log("interval fired");
        console.log({
          userName: userData?.userName,
          userId: tokenObject?.id,
        });

        fetch(
          "https://sales-genius-api.onrender.com/api/checkAndGetAiData?" +
            new URLSearchParams({
              userName: userData?.userName,
              userId: tokenObject?.id,
            })
        )
          .then((response) => {
            console.log("WTFFFF");
            console.log(response);
            return response.json();
          })
          .then((response) => {
            console.log(response);
            if (response?.aiData) {
              setLaterAiData(response.aiData);
            }
          })
          .catch((err) => {
            console.log("something bad happened");
            console.log(err);
          });
      };
      const intervalId = setInterval(intervalFn, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [laterAiData]);

  return (
    <>
      <Row gutter={32} style={{ padding: 16 }}>
        <Col lg={8} md={24} sm={24}>
          <Card
            hoverable
            style={{ padding: 24 }}
            cover={
              <>
                <h4>
                  Which of the 16 Myers-Briggs Personalities is{" "}
                  {linkedinData?.full_name || userData?.userName}?
                </h4>
                <h3 style={{ color: "#3f65b0" }}>
                  {aiData?.sixteenPersonality}
                </h3>
                <img
                  alt="sixteenPersonality"
                  src={process.env.PUBLIC_URL + "/ENTP.jpeg"}
                />
              </>
            }
          >
            <p>{aiData?.sixteenDefinition || <Skeleton active />}</p>
          </Card>
        </Col>

        <Col lg={8} md={24} sm={24}>
          <Card
            hoverable
            style={{ padding: 24 }}
            cover={
              <>
                <h4>
                  Which of the DISC personalities is{" "}
                  {linkedinData?.full_name || userData?.userName}?
                </h4>
                <h3 style={{ color: "#3f65b0" }}>{aiData?.DISCPersonality}</h3>

                <img
                  alt="DISC Personality"
                  src={process.env.PUBLIC_URL + "/ISTJ.jpeg"}
                />
              </>
            }
          >
            <p>{aiData?.DISCDefinition || <Skeleton active />}</p>
          </Card>
        </Col>

        <Col lg={8} md={24} sm={24}>
          <Card
            hoverable
            style={{ padding: 24 }}
            cover={
              <>
                <h4>
                  Which of the Big 5 personalities is{" "}
                  {linkedinData?.full_name || userData?.userName}?
                </h4>
                <h3 style={{ color: "#3f65b0" }}>{aiData?.Big5}</h3>
                <img alt="big5" src={process.env.PUBLIC_URL + "/INTJ.jpeg"} />
              </>
            }
          >
            <p>{aiData?.Big5Definition || <Skeleton active />}</p>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: 16, marginBottom: 16 }}>
        <Col span={24}>
          <Card hoverable style={{ padding: 24 }}>
            <Meta
              title={
                <h3>
                  Temperament: Sliding scale of certain personality traits
                </h3>
              }
              description={
                <div style={{ padding: 24 }}>
                  <h4 style={{ textAlign: "left", color: "black" }}>
                    Risk: How likely they are to take risks -{" "}
                    {personality?.Risk || <Skeleton active />}
                    /10
                  </h4>
                  <Slider min={1} max={10} value={personality?.Risk} />
                  <p style={{ textAlign: "left", color: "#3f65b0" }}>
                    {personality?.Risk < 6
                      ? "Relatively risk adverse"
                      : "Relatively Risk Tolerant"}
                  </p>

                  <Divider></Divider>
                  <h4 style={{ textAlign: "left", color: "black" }}>
                    Trust: How likely they are to be trusting -{" "}
                    {personality?.Trust || <Skeleton active />}/10
                  </h4>
                  <Slider min={1} max={10} value={personality?.Trust} />
                  <p style={{ textAlign: "left", color: "#3f65b0" }}>
                    {" "}
                    {personality?.Trust < 6
                      ? "Relatively Not Trusting"
                      : "Relatively Trusting"}
                  </p>

                  <Divider></Divider>
                  <h4 style={{ textAlign: "left", color: "black" }}>
                    Optimism: How optimistic versus realistic they are -{" "}
                    {personality?.Optimism || <Skeleton active />}/10
                  </h4>
                  <Slider min={1} max={10} value={personality?.Optimism} />
                  <p style={{ textAlign: "left", color: "#3f65b0" }}>
                    {personality?.Optimism < 6
                      ? "More Realistic"
                      : "Relatively Optimistic"}
                  </p>
                  <Divider></Divider>
                  <h4 style={{ textAlign: "left", color: "black" }}>
                    Pace: How fast versus deliberate they are -{" "}
                    {personality?.Pace || <Skeleton active />}
                    /10
                  </h4>
                  <Slider min={1} max={10} value={personality?.Pace} />
                  <p style={{ textAlign: "left", color: "#3f65b0" }}>
                    {personality?.Pace < 6 ? "Deliberate" : "FastPaced"}{" "}
                  </p>
                  <Divider></Divider>
                  <h4 style={{ textAlign: "left", color: "black" }}>
                    Independence: How Independent versus collaborative they are
                    - {personality?.Independence || <Skeleton active />}/10
                  </h4>
                  <Slider min={1} max={10} value={personality?.Independence} />
                  <p style={{ textAlign: "left", color: "#3f65b0" }}>
                    {" "}
                    {personality?.Independence < 6
                      ? "Relatively Collaborative"
                      : "Relatively Independent"}
                  </p>
                </div>
              }
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col lg={6} md={24} sm={24}>
          <Card
            hoverable
            cover={
              <img
                alt="Implication"
                src={process.env.PUBLIC_URL + "/implications.jpeg"}
              />
            }
          >
            <h4>
              Implications: How you should contact{" "}
              {linkedinData?.full_name || userData?.userName}?
            </h4>
            <p>{aiData?.Implications || <Skeleton active />}</p>
          </Card>
        </Col>
        <Col lg={6} md={24} sm={24}>
          <Card
            hoverable
            cover={
              <img
                alt="Intrests"
                src={process.env.PUBLIC_URL + "/interests.jpeg"}
              />
            }
          >
            <h4>
              Interests: What is {linkedinData?.full_name || userData?.userName}{" "}
              in to?
            </h4>
            <p>{aiData?.Interests || <Skeleton active />}</p>
          </Card>
        </Col>
        <Col lg={6} md={24} sm={24}>
          <Card
            hoverable
            cover={
              <img alt="Loves" src={process.env.PUBLIC_URL + "/loves.jpeg"} />
            }
          >
            <h4>
              Loves: What gives {linkedinData?.full_name || userData?.userName}{" "}
              passion and energy?
            </h4>
            <p>{aiData?.Loves || <Skeleton active />}</p>
          </Card>
        </Col>
        <Col lg={6} md={24} sm={24}>
          <Card
            hoverable
            cover={
              <img
                alt="Dislikes"
                src={process.env.PUBLIC_URL + "/hates.jpeg"}
              />
            }
          >
            <h4>
              Hates: What bores and drains{" "}
              {linkedinData?.full_name || userData?.userName}?
            </h4>
            <p>{aiData?.Hates || <Skeleton active />}</p>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AiData;
