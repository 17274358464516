import React from "react";
import { Col, Row, Card, Collapse, List, Button } from "antd";

const MyProfile = ({ tokenObject }) => {
  const items = [
    {
      key: "1",
      label: "Starting Out",
      children: <p>500 Tokens: $19.99/month billed anually</p>,
    },
    {
      key: "2",
      label: "Sales Profesional",
      children: <p>1000 Tokens: $29.99/month billed anually</p>,
    },
    {
      key: "3",
      label: "Sales Leader",
      children: <p>2000 Tokens: $49.99/month billed anually</p>,
    },
    {
      key: "3",
      label: "Enterprise Sales",
      children: <p>Please Contact</p>,
    },
  ];
  return (
    <Card style={{ backgroundColor: "white", minHeight: 500 }}>
      <Row>
        <Col lg={8} md={24}>
          <h4>User Info</h4>
          <p>
            Name: {tokenObject?.firstName} {tokenObject?.lastName}
          </p>
          <p>{tokenObject?.headline}</p>
          <p>Tokens: {tokenObject?.tokens}</p>

          <Button type="link">My AI Profile</Button>
        </Col>
        <Col lg={4} md={24}>
          <h4>Prviously Looked Up</h4>

          <List
            size="small"
            dataSource={[...new Set(tokenObject?.alreadySearched)]}
            renderItem={(item) => <List.Item>{item}</List.Item>}
            style={{ maxHeight: 400, overflow: "auto" }}
          />
        </Col>
        <Col lg={12} md={24} style={{ paddingLeft: 16, paddingRight: 16 }}>
          <h4>Get More Tokens</h4>

          <Collapse items={items} defaultActiveKey={["1"]} />
        </Col>
      </Row>
    </Card>
  );
};

export default MyProfile;
