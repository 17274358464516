import React, { useState, useEffect } from "react";
import LinkedInLogin from "./LinkedInLogin.js";
import {
  Card,
  Row,
  Col,
  Avatar,
  Button,
  Collapse,
  Input,
  Popover,
  Slider,
  Divider,
  Tabs,
} from "antd";
import { UserOutlined, FontColorsOutlined } from "@ant-design/icons";
import RawData from "./RawData.js";
import AiData from "./AiData.js";
import CustomQuestion from "./CustomQuestion.js";
import CustomMessage from "./CustomMessage.js";
import "react-quill/dist/quill.snow.css";
const { Meta } = Card;

const DataCards = ({ userData, tokenObject }) => {
  const items = [
    {
      key: "1",
      label: "LinkedIn Profile",
      children: <RawData userData={userData} tokenObject={tokenObject} />,
    },
    {
      key: "2",
      label: "AI Personality",
      children: <AiData userData={userData} tokenObject={tokenObject} />,
    },
    {
      key: "3",
      label: "Profile Inquiry",
      children: (
        <CustomQuestion userData={userData} tokenObject={tokenObject} />
      ),
    },
    {
      key: "4",
      label: "Custom Messages",
      children: <CustomMessage userData={userData} tokenObject={tokenObject} />,
    },
  ];

  return (
    <>
      <Card
        style={{
          padding: 8,
          background: "white",
        }}
      >
        <Tabs defaultActiveKey="1" type="card" items={items} />
      </Card>
    </>
  );
};

export default DataCards;
