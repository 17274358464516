import React, { useState, useEffect } from "react";
import { Col, Row, Table, Tag, Select, Divider, Button, Input } from "antd";

import {
  SearchOutlined,
  BulbOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import UserProfile from "./UserProfile";
const Employees = ({ user, selectedJob }) => {
  const [employees, setEmployees] = useState([]);
  const [tableParams, setTableParams] = useState();
  const [tempData, setTempData] = useState();
  const [selectedUsers, setSelectedUsers] = useState();

  useEffect(() => {
    setEmployees([]);
    fetch("https://sales-genius-api.onrender.com/api/employees", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: user,
        selectedJob: selectedJob,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response) {
          setEmployees(response?.employees?.people);
        }
      })
      .catch((err) => {
        console.log("something bad happened");
        console.log(err);
      });
  }, [selectedJob]);

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  console.log(employees);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, data) => (
        <div>
          <img
            src={data?.photo_url}
            style={{ width: 60 }}
            onError={(e) =>
              (e.target.src = process.env.PUBLIC_URL + "/unknown.jpeg")
            }
          />
          <p>{data?.name}</p>
          <div style={{ display: "block" }}>
            {data?.linkedin_url ? (
              <a href={data?.linkedin_url} target="_blank">
                <LinkedinOutlined />
              </a>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (_, data) => (
        <div>
          <p>{data.title}</p>
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "organization",
      render: (_, data) => (
        <div>
          <img src={data?.organization?.logo_url} style={{ width: 50 }} />
          <a href={data?.organization?.website_url} target="_blank">
            <p>{data?.organization?.name}</p>
          </a>
          <div style={{ display: "block" }}>
            {data?.organization?.linkedin_url ? (
              <a href={data?.organization?.linkedin_url} target="_blank">
                <LinkedinOutlined />
              </a>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Seniority",
      dataIndex: "seniority",
      render: (_, data) => (
        <div>
          <p>{data.seniority}</p>
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "city",
      render: (_, data) => (
        <div>
          <p>
            {data.country} {data.city}
          </p>
        </div>
      ),
    },
    {
      title: "Keywords",
      dataIndex: "departments",
      render: (_, data) => {
        const unionArray = [
          new Set([
            ...data?.departments,
            ...data?.subdepartments,
            ...data?.functions,
          ]),
        ];

        const allUniqueArray = Array.from(unionArray[0]);
        console.log(unionArray);
        console.log(allUniqueArray);
        if (allUniqueArray && allUniqueArray.length > 0) {
          return (
            <>
              {allUniqueArray.map((tag) => {
                console.log(tag);
                return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
              })}
            </>
          );
        }
      },
    },
    {
      title: "Get AI Data",
      key: "operation",
      fixed: "right",
      render: (data) => {
        return (
          <Button
            type="primary"
            icon={<BulbOutlined />}
            onClick={() => {
              if (tempData?.length > 0) {
                // tempData.forEach((data) => {
                //   console.log(data);
                //   searchUserData(data.userName);
                // });
                setSelectedUsers(tempData);
              } else {
                setSelectedUsers([data]);
              }
            }}
          >
            Get AI Data!
          </Button>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setTempData(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <div>
      {selectedUsers && selectedUsers.length > 0 ? (
        <div style={{ height: 350, overflow: "auto" }}>
          <UserProfile
            tokenObject={user}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </div>
      ) : (
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey="id"
          columns={columns}
          dataSource={employees}
          onChange={onChange}
          scroll={{
            x: 1300,
            y: 350,
          }}
          pagination={tableParams}
        />
      )}
    </div>
  );
};

export default Employees;
