import React, { useState, useEffect } from "react";

import {
  Card,
  Row,
  Col,
  Avatar,
  Button,
  Collapse,
  Input,
  Popover,
  Slider,
  Divider,
  Tabs,
} from "antd";

import { UserOutlined, FontColorsOutlined } from "@ant-design/icons";

import MessageInput from "./MessageInput";

const CustomQuestion = ({ userData, tokenObject }) => {
  const [prevMessages, setPrevMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageRequest, setMessageRequest] = useState();

  useEffect(() => {
    fetch(
      "https://sales-genius-api.onrender.com/api/getUserMessages?" +
        new URLSearchParams({
          userId: tokenObject.id,
        })
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setPrevMessages(response);
      })
      .catch((err) => {
        console.log("something bad happened");
        console.log(err);
      });
  }, []);

  const askNewMessage = () => {
    setLoading(true);

    fetch("https://sales-genius-api.onrender.com/api/newMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        linkedInData: userData?.linkedinData,
        request: messageRequest,
        userId: tokenObject?.id,
        userName: userData?.userName,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setPrevMessages((prev) => [...prev, response]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("something bad happened");
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div style={{ margin: 16 }}>
      <Input
        size="large"
        placeholder="Request: Brief message about your request so AI can personalize your message."
        prefix={<FontColorsOutlined />}
        style={{ marginBottom: 16 }}
        value={messageRequest}
        onChange={(e) => {
          setMessageRequest(e.target.value);
        }}
      />
      <Button
        style={{ marginTop: 8, display: "block" }}
        type="primary"
        loading={loading ? true : false}
        onClick={() => {
          askNewMessage();
        }}
      >
        New Message
      </Button>
      <Divider></Divider>
      <Tabs
        defaultActiveKey="1"
        size={"large"}
        style={{ marginBottom: 32 }}
        items={prevMessages
          ?.filter((el) => el?.userName === userData?.userName)
          ?.map((message, i) => {
            const id = String(i + 1);
            return {
              label: `Message ${id}`,
              key: id,
              children: (
                <div>
                  <p>Request:</p>
                  <p>{message?.request}</p>
                  <MessageInput messageData={message} />

                  <Button style={{ marginTop: 24, display: "block" }}>
                    Copy Message
                  </Button>
                </div>
              ),
            };
          })}
      />
    </div>
  );
};
export default CustomQuestion;
