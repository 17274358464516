import React, { useState, useEffect } from "react";
import LinkedInLogin from "./LinkedInLogin.js";
import {
  Card,
  Row,
  Col,
  Avatar,
  Button,
  Collapse,
  Input,
  Popover,
  Breadcrumb,
  Divider,
} from "antd";
import { UserOutlined, FontColorsOutlined } from "@ant-design/icons";
import RawData from "./RawData.js";
import ReactQuill from "react-quill";
import DataCards from "./DataCards.js";
import "react-quill/dist/quill.snow.css";
const { Meta } = Card;

const UserProfile = ({ tokenObject, selectedUsers, setSelectedUsers }) => {
  const [userData, setUserData] = useState();

  const [loading, setLoading] = useState(false);
  const [specificUser, setSpecificUser] = useState(0);

  const [userName, setUserName] = useState(
    selectedUsers[0]?.linkedin_url?.replace("http://www.linkedin.com/in/", "")
  );

  useEffect(() => {
    const linkedInUserName = selectedUsers[specificUser]?.linkedin_url?.replace(
      "http://www.linkedin.com/in/",
      ""
    );
    setUserName(linkedInUserName);

    searchUserData(linkedInUserName);
  }, [specificUser]);

  const searchUserData = (name) => {
    console.log("tokenObject");
    console.log(tokenObject);
    setLoading(true);
    fetch(
      "https://sales-genius-api.onrender.com/api/getUserData?" +
        new URLSearchParams({
          userName: name,
          profile: JSON.stringify(tokenObject),
        })
    )
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setUserData(response);
      })
      .catch((err) => {
        console.log("something bad happened");
        setLoading(false);
        console.log(err);
      });
  };

  console.log(userName);

  console.log(selectedUsers[specificUser]);
  const userItems = [];

  selectedUsers?.forEach((user, index) => {
    userItems.push({
      title: (
        <Button
          onClick={() => {
            setSpecificUser(index);
          }}
          type="link"
        >
          {user?.name}
        </Button>
      ),
    });
  });

  return (
    <Card
      style={{
        background: "#f5f5f5",
      }}
    >
      <Breadcrumb
        items={[
          {
            title: (
              <a
                style={{ color: "#1677ff" }}
                onClick={() => {
                  setSelectedUsers([]);
                }}
                type="link"
              >
                Back to Search
              </a>
            ),
          },
          {
            title: <a style={{ color: "#1677ff" }}>Selected Users</a>,
            menu: {
              items: userItems,
            },
          },

          {
            title: selectedUsers[specificUser]?.name,
          },
          {
            title: (
              <div>
                <Input
                  size="small"
                  placeholder="LinkedIn Profile Name"
                  prefix={<UserOutlined />}
                  style={{ marginBottom: 16, width: 400 }}
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />

                <Button
                  size="small"
                  loading={loading}
                  type="primary"
                  style={{
                    marginLeft: 8,
                    backgroundColor: "#3f65b0",
                  }}
                  onClick={() => {
                    searchUserData(userName);
                  }}
                >
                  Search LinkedIn
                </Button>
              </div>
            ),
          },
        ]}
      />

      <Divider style={{ marginTop: 0 }}></Divider>

      {loading ? (
        <l-helix size="150" speed="2.5" color="#2f778d"></l-helix>
      ) : userData ? (
        <DataCards userData={userData} tokenObject={tokenObject} />
      ) : (
        <h4 style={{ color: "lightgray" }}>
          Search LinkedIn user to get started.
        </h4>
      )}
    </Card>
  );
};

export default UserProfile;
