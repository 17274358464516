import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Avatar,
  Spin,
  Button,
  Dropdown,
  Input,
  Tabs,
  Pagination,
  Divider,
  Progress,
} from "antd";
import moment from "moment";
import CustomResume from "./CustomResume";
import CareerCoach from "./CareerCoach";
import Employees from "./Employees";
const { Search } = Input;

const conicColors = {
  "0%": "#ffccc7",
  "50%": "#ffe58f",
  "100%": "#87d068",
};

const Apply = ({ user }) => {
  const [jobSearch, setJobSearch] = useState();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(false);
  const [jobParameters, setJobParameters] = useState();
  const [activeTab, setActiveTab] = useState("1");

  const [resume, setResume] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setResume("");
  }, [selectedJob]);

  const fetchResume = () => {
    console.log("selected job changed");
    setLoading(true);
    fetch("https://sales-genius-api.onrender.com/api/getCustomResume", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: user,
        selectedJob: selectedJob,
      }),
    })
      .then((response) => {
        console.log(response);
        return response.text();
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        const userResumeData = `<h1>${user.firstName} ${user.lastName} </h1> 
        <span>Email: ${user.email || ""} ❖ Phone: ${
          user.phone || ""
        } ❖ <a target="_blank" href="https://www.linkedin.com/in/${
          user.userName
        }/">LinkedIn</a> </span> `;
        setResume(userResumeData + res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onKeyChange = (key) => setActiveTab(key);
  return (
    <div>
      <Card style={{ padding: 16, marginBottom: 24 }}>
        <Search
          placeholder="Software developer in Toronto"
          allowClear
          enterButton="Search"
          size="large"
          onChange={(e) => {
            setJobSearch(e.target.value);
          }}
          onSearch={(e) => {
            fetch("https://sales-genius-api.onrender.com/api/searchJobs", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                searchString: jobSearch,
              }),
            })
              .then((response) => {
                return response.json();
              })
              .then((jobs) => {
                setJobs(jobs.data);
                setJobParameters(jobs.parameters);
                setSelectedJob(jobs.data[0]);
                console.log(jobs);
              });
          }}
        />
      </Card>

      {jobs && jobs.length > 0 ? (
        <Card>
          <Row gutter={16}>
            <Col span={6} style={{ textAlign: "left" }}>
              <p>{jobParameters?.query}</p>
              <Divider></Divider>
              {jobs.map((job, i) => {
                let date = moment(
                  job?.job_posted_at_datetime_utc?.split("T")[0]
                ).fromNow();
                return (
                  <Card
                    key={i}
                    style={{
                      width: "100%",
                      padding: 8,
                      cursor: "pointer",
                      marginBottom: 8,
                      fontFamily: "Poppins",

                      borderColor: job === selectedJob ? "#1677ff" : "black",
                    }}
                    onClick={() => {
                      setSelectedJob(job);
                    }}
                  >
                    <div>
                      {job?.employer_logo ? (
                        <img
                          src={job?.employer_logo}
                          style={{ maxWidth: 100 }}
                        />
                      ) : null}
                    </div>
                    <h2 style={{ margin: 4 }}>
                      {job?.job_title || job?.job_job_title}
                    </h2>
                    <p style={{ margin: 0 }}>{job.employer_name}</p>
                    <p style={{ margin: 0 }}>
                      {job?.job_city} {job?.job_country}
                    </p>
                    <p>{job?.job_description.substring(0, 150)}...</p>
                    <p style={{ color: "gray" }}>Posted {date}</p>
                  </Card>
                );
              })}
              <Pagination
                current={jobParameters?.page}
                simple
                onChange={() => {
                  //TODO call new page
                }}
                total={900}
              />
            </Col>

            <Col span={18}>
              <Card
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  padding: 8,
                  position: "sticky",
                  top: 16,
                }}
              >
                {selectedJob?.employer_logo ? (
                  <img
                    src={selectedJob?.employer_logo}
                    style={{ maxHeight: 100 }}
                  />
                ) : null}
                <h1 style={{ margin: 4 }}>
                  {selectedJob?.job_title || selectedJob?.job_job_title}
                </h1>
                <a href={selectedJob?.employer_website} target="_blank">
                  <p style={{ margin: 0, display: "inline" }}>
                    {selectedJob?.employer_name} |
                  </p>{" "}
                </a>
                <p style={{ margin: 0, display: "inline" }}>
                  {selectedJob?.job_city} {selectedJob?.job_country} |
                </p>{" "}
                <p style={{ margin: 0, display: "inline" }}>
                  ${selectedJob?.job_min_salary} - $
                  {selectedJob?.job_max_salary} /{" "}
                  {selectedJob?.job_salary_period}{" "}
                  {selectedJob?.job_salary_currency}
                </p>{" "}
                <div>
                  <Button
                    type="primary"
                    style={{ margin: 8, marginLeft: 0 }}
                    onClick={() => {
                      onKeyChange("2");
                    }}
                  >
                    Custom Resume
                  </Button>
                  <a target="_blank" href={selectedJob?.job_apply_link}>
                    <Button>Apply now</Button>
                  </a>
                </div>
                <p
                  style={{
                    color: "gray",
                    display: selectedJob ? "block" : "none",
                    margin: 0,
                    marginBottom: 16,
                    display: "inline",
                  }}
                >
                  Posted{" "}
                  {moment(
                    selectedJob?.job_posted_at_datetime_utc?.split("T")[0]
                  ).fromNow()}{" "}
                  expires{" "}
                  {moment(
                    selectedJob?.job_offer_expiration_datetime_utc?.split(
                      "T"
                    )[0]
                  ).fromNow()}
                </p>{" "}
                <p style={{ display: "inline", color: "gray" }}>
                  {" "}
                  | Application Quailty{" "}
                </p>
                <Progress
                  type="dashboard"
                  percent={Math.round(
                    selectedJob?.job_apply_quality_score * 100
                  )}
                  strokeColor={conicColors}
                  size={30}
                />
                <Tabs
                  activeKey={activeTab}
                  onChange={onKeyChange}
                  items={[
                    {
                      key: "1",
                      label: "Job Description",
                      children: (
                        <div style={{ height: "46vh", overflow: "auto" }}>
                          <h4>Application Links</h4>
                          {selectedJob?.apply_options?.map((option) => {
                            return (
                              <a
                                href={option?.apply_link}
                                target="_blank"
                                style={{ display: "inline" }}
                              >
                                {option?.publisher} |{" "}
                              </a>
                            );
                          })}
                          <h2>Job Description</h2>
                          <p style={{ fontFamily: "Poppins", fontSize: 18 }}>
                            {selectedJob?.job_description}
                          </p>
                        </div>
                      ),
                    },
                    {
                      key: "2",
                      label: "Custom Resume",
                      children: (
                        <CustomResume
                          selectedJob={selectedJob}
                          user={user}
                          resume={resume}
                          setResume={setResume}
                          loading={loading}
                          fetchResume={fetchResume}
                        />
                      ),
                    },
                    {
                      key: "3",
                      label: "Career Coach AI",
                      children: (
                        <CareerCoach user={user} selectedJob={selectedJob} />
                      ),
                    },

                    {
                      key: "4",
                      label: "Employees",
                      children: (
                        <Employees user={user} selectedJob={selectedJob} />
                      ),
                    },
                  ]}
                  //onChange={onChange}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      ) : (
        <h2>Search for the perfect career for you.</h2>
      )}
    </div>
  );
};

export default Apply;
