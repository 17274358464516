import React, { useState, useEffect } from "react";
import LinkedInLogin from "./LinkedInLogin.js";
import {
  Card,
  Row,
  Col,
  Avatar,
  Button,
  Collapse,
  Input,
  Popover,
  Typography,
  List,
} from "antd";
const { Meta } = Card;

const RawData = ({ userData }) => {
  const linkedinData = userData?.linkedinData;
  return (
    <div>
      <Row>
        <Col span={24}>
          <Card hoverable>
            <Row>
              <Col lg={6} md={24} sm={24}>
                <img
                  alt="userimage"
                  style={{ width: "100%" }}
                  src={linkedinData?.profile_image_url}
                />
              </Col>
              <Col
                lg={18}
                md={24}
                sm={24}
                style={{ padding: 16, textAlign: "left" }}
              >
                <h3>{linkedinData?.full_name}</h3>
                <h4>{linkedinData?.headline}</h4>
                <p>{linkedinData?.about}</p>
                <p>
                  Title: {linkedinData?.job_title} at {linkedinData?.company}
                </p>
                <p>
                  Location: {linkedinData?.city || linkedinData?.hq_city}{" "}
                  {linkedinData?.country || linkedinData?.hq_country}
                </p>
                <p>Connections: {linkedinData?.connections_count}</p>
                <p>Followers: {linkedinData?.followers_count}</p>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <h2>Experience</h2>
          <Row>
            {linkedinData?.experiences.map((item) => {
              return (
                <Col lg={8} md={24} sm={24}>
                  <Card
                    hoverable
                    style={{ height: 400, overflow: "auto", margin: 16 }}
                  >
                    <a href={item.company_linkedin_url} target="_blank">
                      <h3>{item.company}</h3>
                    </a>
                    <h4>{item.date_range}</h4>
                    <p>{item.location}</p>
                    <p>{item.title}</p>
                    <p>{item.description}</p>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>

        <Col span={24}>
          <h2>Education</h2>
          <Row>
            {linkedinData?.educations.map((item) => {
              return (
                <Col lg={8} md={24} sm={24}>
                  <Card
                    hoverable
                    style={{ height: 300, margin: 16, overflow: "auto" }}
                  >
                    <a href={item.school_linkedin_url} target="_blank">
                      <h4>{item.school}</h4>
                    </a>
                    <p>{item.degree}</p>
                    <p>{item.field_of_study}</p>
                    <p>{item.description}</p>
                    <p>{item.date_range}</p>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RawData;
