import React, { useState } from "react";

import { LinkedIn } from "react-linkedin-login-oauth2";
// You can use provided image shipped by this package or using your own
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";

const LinkedInLogin = () => {
  return (
    <LinkedIn
      clientId="86pu0obgxb7x71"
      redirectUri="https://sales-genius.onrender.com/"
      scope="r_liteprofile r_emailaddress r_basicprofile"
      onSuccess={(code) => {
        console.log(code);
      }}
      onError={(error) => {
        console.log(error);
      }}
    >
      {({ linkedInLogin }) => (
        <img
          onClick={linkedInLogin}
          src={linkedin}
          alt="Using Linked In"
          style={{ maxWidth: "250px", cursor: "pointer" }}
        />
      )}
    </LinkedIn>
  );
};

export default LinkedInLogin;
