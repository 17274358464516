import React, { useState, useEffect } from "react";

import {
  Card,
  Row,
  Col,
  Avatar,
  Button,
  Collapse,
  Input,
  Popover,
  Slider,
  Divider,
  Tabs,
} from "antd";

import { UserOutlined, FontColorsOutlined } from "@ant-design/icons";

import MessageInput from "./MessageInput";

const CustomQuestion = ({ user, selectedJob }) => {
  const [prevMessages, setPrevMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageRequest, setMessageRequest] = useState();

  useEffect(() => {
    setPrevMessages([]);
  }, [selectedJob]);

  console.log(user);
  console.log(selectedJob);

  const askNewMessage = () => {
    setLoading(true);

    fetch("https://sales-genius-api.onrender.com/api/careerCoachAi", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: messageRequest,
        user: user,
        selectedJob: selectedJob,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setPrevMessages((prev) => [...prev, response]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("something bad happened");
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div style={{ margin: 16, height: 300, overflow: "auto" }}>
      <h2>Career Coach AI</h2>
      <h3>
        Career Coach knows your profile, expereience, skills, and the job
        description. Ask them to write your cover letter, answer job specific
        questions or give you advice on how to improve.
      </h3>
      <Input
        size="large"
        placeholder="Write a cover letter for this position."
        prefix={<FontColorsOutlined />}
        style={{ marginBottom: 16 }}
        value={messageRequest}
        onChange={(e) => {
          setMessageRequest(e.target.value);
        }}
      />
      <Button
        style={{ marginTop: 8, display: "block" }}
        type="primary"
        loading={loading ? true : false}
        onClick={() => {
          askNewMessage();
        }}
      >
        ✨ Ask Career Coach
      </Button>
      <Divider></Divider>
      <Tabs
        defaultActiveKey="1"
        size={"large"}
        style={{ marginBottom: 32 }}
        items={prevMessages?.map((message, i) => {
          const id = String(i + 1);
          return {
            label: `Message ${id}`,
            key: id,
            children: (
              <div>
                <p>Query:</p>
                <p>{message?.request}</p>
                <MessageInput messageData={message} />

                <Button
                  style={{ marginTop: 24, display: "block" }}
                  onClick={() => {
                    navigator.clipboard.writeText(message.response);
                  }}
                >
                  Copy Content
                </Button>
              </div>
            ),
          };
        })}
      />
    </div>
  );
};
export default CustomQuestion;
