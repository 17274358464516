import React, { useState } from "react";
import ReactQuill from "react-quill";

const MessageInput = ({ messageData }) => {
  const initialMessage = `
    ${messageData?.header || ""}

    ${messageData?.response}
    `;
  const [value, setValue] = useState(initialMessage);

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        style={{ height: "90%" }}
      />
    </div>
  );
};

export default MessageInput;
