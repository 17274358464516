import React, { useState, useEffect } from "react";

import { Progress, Button } from "antd";

import Editor from "react-simple-wysiwyg";

const CustomResume = ({ loading, resume, setResume, fetchResume }) => {
  useEffect(() => {
    fetchResume();
  }, []);
  const handleDownloadPdf = async (htmlContent) => {
    // Create a hidden iframe
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";

    document.body.appendChild(iframe);

    // Set the content of the iframe to the HTML content
    iframe.srcdoc = htmlContent;

    console.log(iframe);

    // Wait for iframe to load the content
    iframe.onload = () => {
      const win = iframe.contentWindow;
      const style = win.document.createElement("style");
      style.textContent = `
            @page {
              size: auto;
              margin: 0;
            }

          `;
      win.document.head.appendChild(style);

      win.focus();
      win.print();

      // Access the iframe's window and print it as PDF
      iframe.contentWindow.print();
      // Remove the iframe from the DOM after printing
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    };
  };
  return (
    <div>
      <Button
        style={{ marginBottom: 8, marginRight: 16 }}
        onClick={() => {
          console.log("TODO run again");

          fetchResume();
        }}
      >
        ⟳ Rerun
      </Button>
      <Button
        type="primary"
        style={{ marginBottom: 8 }}
        onClick={() => {
          handleDownloadPdf(resume);
        }}
      >
        Download as PDF
      </Button>

      <div style={{ height: "46vh", overflow: "auto" }}>
        {!loading ? (
          <Editor
            value={resume}
            onChange={(e) => {
              console.log(e);
              setResume(e.target.value);
            }}
          />
        ) : (
          <div>
            <h3> Putting the finishing touches on your resume!</h3>
            <Progress percent={99} size="large" status="active" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomResume;
