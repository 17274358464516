import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Avatar,
  Button,
  Collapse,
  Input,
  Popover,
  Slider,
  Divider,
  Tabs,
} from "antd";

import { UserOutlined, FontColorsOutlined } from "@ant-design/icons";

const CustomQuestion = ({ userData, tokenObject }) => {
  useEffect(() => {
    fetch(
      "https://sales-genius-api.onrender.com/api/getUserQuestions?" +
        new URLSearchParams({
          userId: tokenObject.id,
        })
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setPrevQuestions(response);
      })
      .catch((err) => {
        console.log("something bad happened");
        console.log(err);
      });
  }, []);
  const [prevQuestions, setPrevQuestions] = useState([]);
  const [question, setQuestion] = useState();
  const [questionData, setQuestionData] = useState();
  const [loading, setLoading] = useState(false);

  console.log(loading);
  console.log(question);
  console.log(questionData);

  console.log(userData?.linkedinData);

  const askNewQuestion = () => {
    setLoading(true);

    fetch("https://sales-genius-api.onrender.com/api/askQuestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        linkedInData: userData?.linkedinData,
        question: question,
        userId: tokenObject?.id,
        userName: userData?.userName,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setPrevQuestions((prev) => [...prev, response]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("something bad happened");
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <div style={{ margin: 16 }}>
      <Input
        size="large"
        placeholder="Question: Ask any question about this person or company."
        prefix={<FontColorsOutlined />}
        style={{ marginBottom: 16 }}
        value={question}
        onChange={(e) => {
          setQuestion(e.target.value);
        }}
      />
      <Button
        style={{ marginTop: 8, display: "block" }}
        type="primary"
        disabled={question ? false : true}
        loading={loading ? true : false}
        onClick={() => {
          askNewQuestion();
        }}
      >
        New Question
      </Button>
      <Divider></Divider>
      <Tabs
        defaultActiveKey="1"
        size={"large"}
        style={{ marginBottom: 32 }}
        items={prevQuestions
          ?.filter((el) => el?.userName === userData?.userName)
          ?.map((question, i) => {
            let index = i + 1;

            return {
              label: `Question ${index}`,
              key: String(index),
              children: (
                <div>
                  <Card>
                    <h5 style={{ textAlign: "left" }}>
                      Question: {question?.question}
                    </h5>
                    <p style={{ textAlign: "left" }}>
                      Answer: {question?.response}
                    </p>
                  </Card>
                </div>
              ),
            };
          })}
      />
    </div>
  );
};
export default CustomQuestion;
