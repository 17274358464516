import React, { useState, useEffect } from "react";
import LinkedInLogin from "./LinkedInLogin.js";
import { Card, Row, Col, Divider } from "antd";

const LoginPage = () => {
  return (
    <div>
      <section class="section-hero">
        <div class="hero">
          <div class="hero-text-box">
            <p
              class="hero-description"
              style={{ marginTop: 64, marginBottom: 16 }}
            >
              Search for the perfect job across all the main job application
              boards and generate custom resumes for the position.
            </p>
            <p class="hero-description">
              Career Coach AI gives you career advice and answer questions on
              any job.
            </p>
            <p class="hero-description">
              Learn about and message employees at the company. Put your job
              applications on cruise control with Spark Prospect!
            </p>

            <h3>Sign in with LinkedIn to continue</h3>
            <LinkedInLogin />
          </div>
          <div class="hero-img-box">
            <h1 class="heading-primary">Create custom resumes for free!</h1>
            <img src="/sample3.png" alt="Discrepancy AI" class="hero-img" />
          </div>
          <div class="delivered-meals">
            <div class="delivered-imgs">
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-1.jpg"
                alt="Customer photo"
              />
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-2.jpg"
                alt="Customer photo"
              />
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-3.jpg"
                alt="Customer photo"
              />
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-4.jpg"
                alt="Customer photo"
              />
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-5.jpg"
                alt="Customer photo"
              />
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-6.jpg"
                alt="Customer photo"
              />
            </div>
            <p class="delivered-text">
              <span>10,000+</span> Successful Applications!
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPage;
