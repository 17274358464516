import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import "./App.css";

import LoginPage from "./LoginPage";
import UserProfile from "./UserProfile";
import MyProfile from "./MyProfile";
import {
  Card,
  Row,
  Col,
  Avatar,
  Spin,
  Button,
  Dropdown,
  Input,
  Tabs,
} from "antd";

import { helix } from "ldrs";
import SearchUsers from "./SearchUsers";

import Apply from "./Apply";

helix.register();
const { Search } = Input;

const { Meta } = Card;

function App() {
  const [tokenObject, setTokenObject] = useState(false);
  const [profile, setProfile] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [peopleData, setPeopleData] = useState();

  const [name, setName] = useState();
  const [title, setTitle] = useState([]);
  const [keywords, setKeywords] = useState();
  const [location, setLocation] = useState([]);
  const [seniority, setSeniority] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [tableParams, setTableParams] = useState();

  console.log(profile);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let code = params.get("code");
  let state = params.get("state");

  useEffect(() => {
    const to = localStorage.getItem("tokenObject");

    if (to) {
      console.log(JSON.parse(to));
      setTokenObject(JSON.parse(to));
    }
  }, []);

  // useEffect(() => {
  //   setTokenObject({
  //     _id: "6559809404bb7592dd4beb06",
  //     id: "QIe07gsloF",
  //     __v: 0,
  //     alreadySearched: [
  //       "lisen-kaci",
  //       "rashelhariri",
  //       "lisen-kaci",
  //       "lisen-kaci",
  //       "lisen-kaci",
  //       "lisen-kaci",
  //       "lisen-kaci",
  //       "lisen-kaci",
  //       "lisen-kaci",
  //       "cameron-stirling-75703512b",
  //       "lisen-kaci",
  //       "lisen-kaci",
  //     ],
  //     firstName: "Lisen",
  //     firstUsed: "2023-11-19T03:27:16.586Z",
  //     headline: "Engineering Lead",
  //     lastName: "Kaci",
  //     lastUsed: "2023-12-08T17:50:52.271Z",
  //     tokens: 25,
  //     userName: "lisen-kaci",
  //   });
  // }, []);

  useEffect(() => {
    console.log(code);
    console.log(state);

    console.log(tokenObject || localStorage.getItem("tokenObject"));

    if (
      code &&
      state &&
      !(tokenObject || localStorage.getItem("tokenObject"))
    ) {
      fetch(
        "https://sales-genius-api.onrender.com/oauth/linkedin/callback?" +
          new URLSearchParams({
            code: code,
            state: state,
          })
      )
        .then((response) => {
          console.log(response);

          return response.json();
        })
        .then((response) => {
          console.log(response);
          if (response && response.id) {
            setTokenObject(response);
            localStorage.setItem("tokenObject", JSON.stringify(response));
          }
        })
        .catch((err) => {
          console.log("something bad happened");
          console.log(err);
        });
    }
  }, [code, state, JSON.stringify(tokenObject)]);

  const onChange = (key) => {
    console.log(key);
  };

  const tabs = [
    {
      key: "1",
      label: "Apply",
      children: <Apply user={tokenObject} />,
    },
    {
      key: "2",
      label: "People Lookup",
      children: (
        <div style={{ background: "white" }}>
          {tokenObject && tokenObject.id ? (
            !(selectedUsers?.length > 0) ? (
              <SearchUsers
                tokenObject={tokenObject}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                peopleData={peopleData}
                setPeopleData={setPeopleData}
                name={name}
                setName={setName}
                title={title}
                setTitle={setTitle}
                keywords={keywords}
                setKeywords={setKeywords}
                location={location}
                setLocation={setLocation}
                seniority={seniority}
                setSeniority={setSeniority}
                tempData={tempData}
                setTempData={setTempData}
                tableParams={tableParams}
                setTableParams={setTableParams}
              />
            ) : (
              <UserProfile
                tokenObject={tokenObject}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
            )
          ) : code || state ? (
            // Default values shown
            <l-helix size="150" speed="2.5" color="#2f778d"></l-helix>
          ) : (
            <LoginPage />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: "My Profile",
      children: <MyProfile tokenObject={tokenObject} />,
    },
  ];

  return (
    <div
      className="App"
      style={{
        height: "100%",
        padding: 0,
      }}
    >
      <Card
        style={{
          background: "#b8e5ea",
          border: "none",
          padding: 0,
          borderRadius: 0,
        }}
        bodyStyle={{ padding: "0" }}
      >
        <Row>
          <Col lg={10} md={24} sm={24}>
            <img
              src="/name.png"
              alt="Sales Genius"
              style={{
                width: "50%",
                float: "left",
                margin: 16,
                float: "left",
              }}
            />
          </Col>

          {tokenObject && tokenObject.id ? (
            <Col lg={10} md={24} sm={24}></Col>
          ) : null}

          {tokenObject && tokenObject.id ? (
            <Col lg={4} md={24} sm={24}>
              <div style={{ width: "100%" }}>
                <img
                  src={process.env.PUBLIC_URL + "INFJ.jpeg"}
                  style={{
                    width: 50,
                    borderRadius: "50%",
                    float: "left",
                    margin: 8,
                  }}
                />
                <h3
                  style={{
                    padding: 0,
                    marginTop: 20,
                    marginBottom: 0,
                    color: "black",
                    float: "left",
                  }}
                >
                  {tokenObject?.firstName} {tokenObject?.lastName}
                </h3>
                <Button
                  style={{ float: "left" }}
                  onClick={() => {
                    localStorage.removeItem("tokenObject");
                    window.location.href = "https://sales-genius.onrender.com/";
                  }}
                >
                  Logout
                </Button>
              </div>
            </Col>
          ) : null}
        </Row>
      </Card>

      {tokenObject ? (
        <Tabs
          defaultActiveKey="1"
          items={tabs}
          onChange={onChange}
          style={{ margin: 24 }}
        />
      ) : (
        <LoginPage />
      )}

      <Card
        style={{
          marginTop: 150,
          background: "#b8e5ea",
          border: "none",
          height: "100%",
          padding: 24,
          borderRadius: 0,
        }}
      >
        <Row gutter={16}>
          <Col lg={11} md={24} sm={24}>
            <img
              src="/name.png"
              alt="Sales Genius"
              style={{ width: "80%", float: "left", marginTop: 10 }}
            />

            <p
              style={{
                display: "inline-block",
                textAlign: "left",
              }}
            >
              Spark Prospect Aggregates all the jobs from the top job boards. It
              creates custom resumes for you based on your experience for every
              job. Our Career Coach AI helps you write cover letters, answer
              questions, and gives advice. Our Employee Search lets you find
              decision makers at a company and find the best ways to contact
              them.
            </p>
          </Col>

          <Col lg={4} md={24} sm={24}>
            {/* <a href="https://www.sparkprospect.com/prospecting-platform">
              <h3 style={{ textAlign: "left" }}>Prospecting Platform</h3>
            </a>
            <a href="https://www.sparkprospect.com/platform/get-linkedin-personality-assessments">
              <p style={{ textAlign: "left" }}>Personality Assessments</p>
            </a>
            <a href="https://www.sparkprospect.com/platform/pitch-builder">
              <p style={{ textAlign: "left" }}>Pitch Creation</p>
            </a>
            <a href="https://www.sparkprospect.com/platform/ai-prospect-inquiry">
              <p style={{ textAlign: "left" }}>Prospect Inquiry</p>
            </a> */}
          </Col>

          <Col lg={4} md={24} sm={24}>
            {/* <a href="https://www.sparkprospect.com/solutions/sales-teams">
              <h3 style={{ textAlign: "left" }}>Solutions</h3>
            </a>
            <a href="https://www.sparkprospect.com/solutions/sales-teams">
              <p style={{ textAlign: "left" }}>Sales Teams</p>
            </a>
            <a href="https://www.sparkprospect.com/platform/pitch-builder">
              <p style={{ textAlign: "left" }}>Marketing & PR</p>
            </a>
            <a href="https://www.sparkprospect.com/solutions/talent-teams-and-job-applicants">
              <p style={{ textAlign: "left" }}>Job applicants</p>
            </a>
            <a href="https://www.sparkprospect.com/solutions/investor-prospecting">
              <p style={{ textAlign: "left" }}>Investor Outreach</p> */}
            {/* </a> */}
          </Col>
          <Col lg={5} md={24} sm={24}>
            {/* <a href="https://www.sparkprospect.com/about">
              <h3 style={{ textAlign: "left" }}>Company</h3>
            </a>
            <a href="https://www.sparkprospect.com/about">
              <p style={{ textAlign: "left" }}>About</p>
            </a>
            <a href="https://www.sparkprospect.com/blog">
              <p style={{ textAlign: "left" }}>Blog</p>
            </a>
            <a href="https://www.sparkprospect.com/contact">
              <p style={{ textAlign: "left" }}>Contact</p>
            </a> */}
          </Col>
        </Row>{" "}
      </Card>
    </div>
  );
}

export default App;
